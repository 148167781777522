<script setup lang="ts">
import type { KeenSliderInstance, KeenSliderOptions } from "keen-slider";

import type { HomeJackpots, Game } from "@/types";

const { t } = useT();
const router = useRouter();

const DEFAULT_INCREMENT_STEP = 0.15;
const DEFAULT_INCREMENT_INTERVAL = 550;

const props = defineProps<{
	gameMode?: string;
	jackpots?: HomeJackpots;
	games: Game[];
}>();

const prepareImgUrl = useImage();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const imagePattern = `url(${prepareImgUrl(`/nuxt-img/card-games/pattern.webp`, {
	format: "webp",
	width: 300
})})`;
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });
const { data: appInitData } = useAppInitData();

const intervalId = ref<ReturnType<typeof setInterval>>();
const amount = ref(0);
const arraybleAmount = ref<string[]>(["0"]);

const shouldCounterDisplay = computed(() => arraybleAmount.value?.some((num) => num !== "0"));
const isSweepStakes = computed(() => props.gameMode === "SweepStakes");
const totalWinAmount = computed(() => {
	const values = props.jackpots?.[isSweepStakes.value ? "USD" : "TRN"]?.values ?? [];
	return values.reduce((acc, item) => acc + (item.amount ?? 0), 0) * 100;
});
const sliderRef = ref();
const sliderActiveSlide = ref(0);
const sliderOptions: KeenSliderOptions = {
	loop: true,
	mode: "snap",
	breakpoints: {
		"(max-width: 767px)": {
			slides: {
				perView: "auto",
				spacing: 8
			}
		}
	},
	slides: { perView: "auto", spacing: 16 },
	slideChanged(slider: KeenSliderInstance) {
		sliderActiveSlide.value = slider.track.details.abs;
	}
};

const incrementValue = () => {
	amount.value += DEFAULT_INCREMENT_STEP;
	if (isSweepStakes.value) {
		arraybleAmount.value = numberFormat(amount.value, { minimumFractionDigits: 2 }).replace(/\.\d*/, "").split("");
		return;
	}
	arraybleAmount.value = numberFormat(amount.value * 100).split("");
};

const goToJackpotGames = () => {
	router.push("/issues/jackpot/");
};

watch(totalWinAmount, () => {
	amount.value = totalWinAmount.value;
});

onMounted(() => {
	amount.value = totalWinAmount.value;
	intervalId.value = setInterval(incrementValue, DEFAULT_INCREMENT_INTERVAL);
});
onUnmounted(() => clearInterval(intervalId.value));
</script>

<template>
	<OLazyComponent v-if="shouldCounterDisplay" id="jackpot-games">
		<section class="jackpot">
			<div class="values" data-tid="slider-title-jackpot-games">
				<AText
					:size="{ full: 56, md: 32 }"
					:modifiers="['bold', 'center']"
					class="amount text-tlalnepantla"
					data-tid="jackpot-value"
					:gradient="isSweepStakes ? 'var(--ghaziabad)' : 'var(--gujrat)'"
					as="div"
				>
					<TransitionGroup name="counter">
						<span
							v-for="(item, index) in arraybleAmount"
							:key="item + index"
							:class="['amount-item', { 'is-separator': isNaN(parseInt(item)) }]"
						>
							{{ item }}
						</span>
					</TransitionGroup>
				</AText>
			</div>
			<div class="content">
				<div class="nav nav__prev" data-tid="jackpot-prev" @click="sliderRef.slider?.prev()">
					<NuxtIcon class="swiper-button" name="40/left-slider" />
				</div>
				<div class="nav nav__next" data-tid="jackpot-next" @click="sliderRef.slider?.next()">
					<NuxtIcon class="swiper-button" name="40/right-slider" />
				</div>
				<ASlider ref="sliderRef" :options="sliderOptions">
					<div v-for="(game, i) in games" :key="i" class="swiper-slide keen-slider__slide">
						<MGame
							:key="game?.id"
							:is-guest="!!appInitData?.isGuest"
							:is-only-for-app="!!game?.isOnlyForApp"
							:is-favorite="!!game?.isFavorite"
							:is-new="!!game?.isNew"
							:image="`${baseImageUrl}${game?.logo || game?.img}`"
							:image-pattern="imagePattern"
							:game-id="game?.id || 0"
							:game="game"
							:insideTitle="game?.title || ''"
							:skeleton="'skeleton' in game"
							@toggle-favorite="handleToggleToFavoriteClick(game)"
						/>
					</div>
				</ASlider>

				<div class="action">
					<AButton
						variant="primary"
						data-tid="slider-jackpot-games-all"
						class="btn"
						size="xl"
						@click="goToJackpotGames"
					>
						<AText :size="20" :modifiers="['semibold']" class="text-tlalnepantla">
							{{ t("jackpot.home.btn.name") }}
						</AText>
					</AButton>
				</div>
			</div>
		</section>
	</OLazyComponent>
</template>

<style scoped lang="scss">
:deep {
	.keen-slider {
		width: 924px !important;
		max-width: 100%;
		padding-bottom: 24px;
		z-index: 5;
	}

	.swiper-slide {
		flex-shrink: 0;

		.game-wrapper {
			width: 172px;

			@include media-breakpoint-down(lg) {
				@media (orientation: landscape) {
					width: 110px;
				}
			}

			@include media-breakpoint-down(md) {
				width: 100px;
			}
		}
	}
}
.counter {
	&-enter-from,
	&-leave-to {
		opacity: 0;
	}

	&-enter-from {
		transform: translateY(40px);
	}

	&-leave-active {
		position: absolute;
	}

	&-leave-to {
		transform: translateY(-40px);
	}
}
.jackpot {
	width: 1307px;
	max-width: calc(100% - 32px);
	height: 535px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 40px 80px;
	margin: 136px auto 24px;
	border: 1px solid var(--cannes);
	box-shadow:
		0px 0px 6px 0px var(--cangzhou),
		0px 4px 4px 0px rgba(255, 225, 180, 0.25) inset,
		0px 0px 18px 0px rgba(255, 152, 220, 0.76) inset;
	background-image: url("/nuxt-img/jackpot/jackpot-bg.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 20px;

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			height: 385px;
		}
	}

	@include media-breakpoint-down(md) {
		background-image: url("/nuxt-img/jackpot/jackpot-bg-mob.jpg");
		background-position: 50%;
		height: 365px;
		margin: 88px auto 24px;
		padding: 40px 16px;
	}

	.values {
		position: absolute;
		top: -80px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 610px;
		height: 240px;
		padding-top: 48px;
		background-image: url("/nuxt-img/jackpot/jackpot-header.png");
		background-repeat: no-repeat;
		background-size: cover;

		@include media-breakpoint-down(lg) {
			@media (orientation: landscape) {
				padding-top: 28px;
				top: -88px;
				width: 434px;
				height: 173px;
				background-size: contain;
			}
		}

		@include media-breakpoint-down(md) {
			padding-top: 28px;
			top: -88px;
			width: 434px;
			height: 173px;
			background-size: contain;
		}

		@include media-breakpoint-down(sm) {
			background-size: cover;
			background-position: 50%;
		}

		@media (max-width: 450px) {
			width: 100%;
			background-size: 120%;
		}
	}

	.content {
		max-width: 100%;
		padding-top: 92px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 32px;
		z-index: 1;

		@include media-breakpoint-down(md) {
			margin-top: 16px;
		}
	}

	.nav {
		position: absolute;
		top: calc(50% - 30px);
		font-size: 52px;
		@include media-breakpoint-down(md) {
			display: none;
		}
		&:hover {
			cursor: pointer;
		}
		&__prev {
			left: -70px;
		}

		&__next {
			right: -70px;
		}
	}

	.amount {
		position: relative;
		top: 4px;
		overflow: hidden;
		line-height: 64px;

		&:deep {
			> span {
				background-image: var(--a-text-gradient);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		&-item:not(.is-separator) {
			transition: all 0.55s ease;
			display: inline-block;
			width: 32px;
			@include media-breakpoint-down(lg) {
				@media (orientation: landscape) {
					width: 18px;
				}
			}
			@include media-breakpoint-down(md) {
				width: 18px;
			}
		}

		@include media-breakpoint-down(lg) {
			@media (orientation: landscape) {
				font-size: 32px;
			}
		}
	}
}
.action {
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	user-select: none;
}

.btn {
	width: 280px;

	@include media-breakpoint-down(md) {
		width: 220px;
	}
}

.event-icon {
	position: absolute;

	&.left {
		width: 137px;
		height: 135px;
		left: 230px;
		top: -150px;

		@include media-breakpoint-down(lg) {
			left: -10px;
		}

		@include media-breakpoint-down(md) {
			width: 67px;
			height: 66px;
			top: -115px;
		}
	}

	&.right {
		width: 108px;
		height: 82px;
		right: 130px;
		top: -113px;

		@include media-breakpoint-down(lg) {
			width: 54px;
			height: 41px;
			right: 0;
			top: -100px;
		}
	}
}
</style>
